import React, {useState} from 'react';
import s from "./Laserdelete.module.css"
import Procedure from "../Procedure/Procedure";
import SliderDoPosle from "../SliderDoPosle/SliderDoPosle";
import imageDo4 from "../../image/sosyd1.png"
import imagePosle4 from "../../image/sosyd2.png"
import imageDo3 from "../../image/sosyd3.png"
import imagePosle3 from "../../image/sosyd4.png"
import imageDo2 from "../../image/sosyd5.png"
import imagePosle2 from "../../image/sosyd6.png"
import imageDo1 from "../../image/sosyd7.png"
import imagePosle1 from "../../image/sosyd8.png"
import TablePrice from "../TablePrice/TablePrice";
import Modal from "../Modal/Modal";
import Zagolovok from "../Zagolovok/Zagolovok";
import Protivopokazaniya from "../Protivopokazaniya/Protivopokazaniya";


const opis = "-это эффективная методика для борьбы с сосудистыми проблемами на коже. Вот некоторые ключевые аспекты этой процедуры."
const plus1 ="Принцип действия: Лазерный луч проникает в кожу, равномерно прогревая всю толщу стенки сосуда без повреждения окружающих тканей. Это приводит к коагуляции кровеносных сосудов, закрывая крупные очаги сосудистых поражений и обеспечивая идеальный эстетический эффект"
const plus2="Процедура: Продолжительность процедуры варьируется от 5 до 30 минут. Обычно достаточно 1-5 сеансов, в зависимости от зоны и индивидуальных особенностей пациента. Стоимость лазерного удаления сосудов начинается от 1000 рублей"
const plus3 = "Восстановление: После процедуры может потребоваться 3-14 дней для полного восстановления."
const plus4 = "Безопасность: Лазерное удаление сосудов выполняется безопасно, не повреждая здоровые ткани. В отличие от других методов, таких как электрокоагуляция или радиоволновой нож, лазерное воздействие селективно направлено на стенку сосудов, что исключает риск рубцевания тканей"

const dataWomen = [
    { id:2, time:'20 мин', name: 'Вокруг глаз', price: '5000₽' },
    { id:3, time:'25 мин', name: 'Крылья носа', price: '3900₽' },
    { id:4, time:'30 мин', name: 'Нос полностью', price: '4900₽' },
    { id:5, time:'25 мин', name: 'Подбородок', price: '4500₽' },
    { id:6, time:'35 мин', name: 'Лицо полностью', price: '12500₽' },
    { id:7, time:'10 мин', name: 'Ангиома за 1шт', price: '2500₽' },
    { id:8, time:'20 мин', name: 'Ангиомы от 2-9шт за 1шт', price: '490₽' },
    { id:9, time:'45 мин', name: 'Ангиомы от 30шт за 1шт', price: '190₽' },
    { id:10, time:'40 мин', name: 'Неодимовое омоложение лица', price: '14000₽' },
    { id:11, time:'35 мин', name: 'Неодимовое омоложение шеи', price: '10000₽' },
    { id:12, time:'25 мин', name: 'Неодимовое омоложение декольте', price: '13000₽' },
    { id:13, time:'30 мин', name: 'Неодимовое омоложение рук', price: '5000₽' },
    { id:14, time:'35 мин', name: 'Лазерное неодимовое лечение онихомикоза ', price: '2500₽'},
    { id:15, time:'35 мин', name: 'Одна анатомическая область ', price: '5000-8000₽'},
    { id:1, time: '5 мин', name: '1 квадратный сантиметр', price: '1000₽' },
];

const Laserdelete = () =>{
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const openModalWithDelay = () => {
        // Устанавливаем состояние модального окна в true через 500 миллисекунд (половина секунды)
        setTimeout(() => {
            setIsModalOpen(true);
        }, 300);
    };

    return <div className={s.wrap}>
        <div>
            <Modal isOpen={isModalOpen} onClose={closeModal}/>
        </div>
        <Procedure nameProcedure={"Лазерное удаление сосудов"} opisanie={opis} plus1 ={plus1} plus2={plus2} plus3={plus3} plus4={plus4}></Procedure>
        <SliderDoPosle imageDo1={imageDo1} imagePosle1={imagePosle1} imageDo2={imageDo2} imagePosle2={imagePosle2}
                       imageDo3={imageDo3} imagePosle3={imagePosle3} imageDo4={imageDo4} imagePosle4={imagePosle4}

        ></SliderDoPosle>
        <Zagolovok showLine={false} textH1={"Противопоказания"}></Zagolovok>
        <Protivopokazaniya protiv1={"активная инсоляция (загорелая кожа)"} protiv2={"️повреждения и заболевания кожи в области проведения процедуры"} protiv3={"системные заболевания крови, хронический ДВС синдром, коагулопатии"} protiv4={"онкологические заболевания"}
                           protiv5={"острые инфекционные заболевания, обострение хронических заболеваний"} protiv6={"активный инфекционный процесс в зоне предполагаемой обработки"} protiv7={"беременность, лактация"} ></Protivopokazaniya>
        <TablePrice data={dataWomen} caption={"ПРАЙС"}></TablePrice>

    </div>
}

export default Laserdelete;
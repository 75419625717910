import React,{useState, useRef } from 'react'
import s from './Modalzapis.module.css';
import {createPortal} from "react-dom";
import NavButtonZapis from "../Buttons/navButtonZapis/NavButtonZapis";
import {FaTimes} from "react-icons/fa";
import { useSpring, animated } from 'react-spring';
import emailjs from '@emailjs/browser';
import Lottie from "lottie-react";
import animData from '../../chekAnim.json'
const mountElement = document.getElementById("overlays");


const Modal = ({ isOpen, onClose, children }) => {
    const [isModalVisible, setModalVisible] = useState(true);
    const [isFormVisible, setFormVisible] = useState(false);
    const form = useRef();

    const [isFormFilled, setIsFormFilled] = useState(false);

    const checkFormFilled = () => {
        const nameValue = form.current.elements.name.value;
        const phoneValue = form.current.elements.phone.value;
        // Проверка на заполненность полей
        const filled = nameValue.trim() !== "" && phoneValue.trim() !== "";
        // Обновление состояния isFormFilled
        setIsFormFilled(filled);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_g9z0hvu', 'template_fk7uw16', form.current, 'p6B5Giq_DINu5X1yb')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        setModalVisible(false);
        setFormVisible(true);
        closeModalWithDelay();
    };

    const closeModalWithDelay = () => {
        // Устанавливаем состояние модального окна в true через 500 миллисекунд (половина секунды)
        setTimeout(() => {
            onClose();
            setModalVisible(true);
            setFormVisible(false);
        }, 2500);
    };
    const modalAnimation = useSpring({
        opacity: isOpen ? 1 : 0,
        transform: isOpen ? 'opacity(0%)' : 'opacity(100%)',
    });

    if (!isOpen) return null;
    return createPortal(
            <animated.div className={s.login} style={modalAnimation}>
                {isModalVisible && (
                    <form ref={form} onSubmit={sendEmail} onChange={checkFormFilled} className={s.login__form}>
                        <h1 className={s.login__title}>Получи скидку 30%</h1>
                        <div className={s.login__inputs}>
                            <div className={s.login__box}>
                                <input type="text" placeholder={"Ваше Имя"} name="name" className={s.login__input}></input>
                            </div>
                            <div className={s.login__box}>
                                <input  type="tel" name="phone" placeholder={"Ваше телефон"} className={s.login__input}></input>
                                <i className="ri-lock-2-fill"></i>
                            </div>
                        </div>
                        <div>
                            <button type="submit" value="Send" disabled={!isFormFilled} className={s.button}>Получить</button>
                        </div>
                        {/*<NavButtonZapis buttonText="Получить"></NavButtonZapis>*/}
                        <div className={s.buttonContainer}>
                            <button className={s.close__button} onClick={onClose}>
                                <FaTimes/>
                            </button>
                        </div>
                    </form>
                )}
                {isFormVisible && (
                    <Lottie animationData={animData}/>
                )}
            </animated.div>


        ,mountElement)
}

export default Modal;
import React from 'react';
import s from './Footer.module.css'
import CustomLink from "../CustomLink/CustomLink";

const Footer = () => {
    return <footer >
        <div className={s.footer}>
            <p>Наша миссия - предоставлять качественные и безопасные услуги по уходу за кожей, волосами и телом с помощью современных лазерных технологий.</p>
            TopLaser - это ваш надежный партнер в достижении идеального образа.
        </div>
    </footer>
}

export default Footer;
import React, { useEffect, useRef, useState } from "react";
import CustomLink from "../CustomLink/CustomLink";
import {FaBars, FaTimes} from 'react-icons/fa'
import logo from '../../image/Logo.png'
import NavButtonZapis from "../Buttons/navButtonZapis/NavButtonZapis";
import s from './Navbar2.module.css'


const Navbar = ({openModal}) => {

    const navRef = useRef();
    const closeNavbar = () => {
        navRef.current.classList.remove(s.responsive_nav);
    };
    const showNavbar = () => {
        navRef.current.classList.toggle(s.responsive_nav)
    }

    return <div className={s.wrap}  >
        <div >
            <CustomLink to="/" >
                <img src={logo} alt=""/>
            </CustomLink>
        </div>
        <div><NavButtonZapis openModal={openModal} buttonText="Получить скидку" isModal={true}/></div>
        <nav ref={navRef} className={s.nav}>
            <div className={s.blockLink}>
                <CustomLink to="/" onClick={closeNavbar}>Главная</CustomLink>

                <CustomLink to="/laserepil" onClick={closeNavbar}>Лазерная эпиляция</CustomLink>

                <CustomLink to="/laserdelete" onClick={closeNavbar}>Лазерное удаление сосудов</CustomLink>

                <CustomLink to="/lpg" onClick={closeNavbar}>LPG Массаж</CustomLink>

                <CustomLink to="/contacts" onClick={closeNavbar}>Контакты</CustomLink>
            </div>

            <button className={`${s.navBtn} ${s.navCloseBtn}`} onClick={showNavbar}>
                <FaTimes/>
            </button>

        </nav>
        <button className={s.navBtn} onClick={showNavbar}>
            <FaBars/>
        </button>

    </div>
}

export default Navbar;
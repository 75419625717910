import React from 'react';
import s from './Header.module.css'
import CustomLink from "../CustomLink/CustomLink";
import { SocialIcon } from 'react-social-icons';

const Header = () => {
    return <header className={s.header}>
        <CustomLink to="tel:+79832300938" style={{ color: 'white'}}>+7 983 230-09-38</CustomLink>
        <div><SocialIcon bgColor="#5c5a5b" style={{ height: 35, width: 35 }}  url="https://www.instagram.com/toplaser_tomsk/" /></div>
        <div>ПН-ВС с 9:00 до 21:00</div>
        <div><SocialIcon bgColor="#5c5a5b" style={{ height: 35, width: 35 }} url="https://vk.com/tomsk_epilation_toplaser" /></div>
    </header>
}

export default Header;